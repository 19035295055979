body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.logo {
  width: 111.609px;
  height: 176.957px;
  background-color: #232F84;
}

.logo img {
  width: 65.573px;
  height: 69.189px;
  flex-shrink: 0;
  margin: 30px 25px 8.76px 25px;
}

.logo_text {
  color: #FFF;
  text-align: center;
  font-family: Helvetica Neue;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 19.2px */
  letter-spacing: -0.32px;
}

.nav-items {
  display: flex;
  width: 100%;
  position: absolute;
}

.nav-menu {
  display: flex;
  width: 1200px;
  top: 30px;
  align-items: center;
  z-index: 999999;
  position: absolute;
  margin-left: 216px;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 313px;

}

a {
  text-decoration: none;
}

.name {
  color: #232F84;
  font-family: Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: 0.1px;
  padding-right: 94px;
}

.links1 {
  display: flex;
  gap: 28px;
}

.links2 {
  display: flex;
  gap: 45px;
  align-items: center;
  z-index: 9999;
}

.join_button {
  padding: 15px 25px;
  border-radius: 5px;
  background: #FD0;
  border: none;
  color: #232F84;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.2px;
}


.join_button svg {
  padding-left: 5px;
}

.link {
  color: #232F84;
  text-align: center;
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 171.429% */
  letter-spacing: 0.2px;
}

.link2 {
  color: #FD0;
  text-align: right;
  /* btn-text */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  /* 157.143% */
  letter-spacing: 0.2px;
}

.banner {
  display: grid;
  grid-template-columns: 60% 40%;
}

.bannerimage {
  height: 700px;
  background-color: #232F84;
  position: relative;
}

.img {
  width: 100%;
  height: 700px;
  flex-shrink: 0;
  position: absolute;
  top: 80.09px;
  right: 30px;
  object-fit: contain;

  z-index: 99;



}

.bannertexts {
  padding-left: 111.609px;
  padding-top: 180px;
}

.allbannertexts {
  padding-left: 101.39px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.headersmall {
  color: var(--primary-text-color, #FF6551);

  /* h5 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.1px;
  padding-top: 7.04px;
}

.headerlarge {
  color: #35257A;
  font-family: Helvetica;
  font-size: 58px;
  font-style: normal;
  font-weight: 700;
  line-height: 65px;
  /* 112.069% */
  letter-spacing: 0.2px;
  width: 542px;
}

.access {
  width: 438px;
  color: var(--second-text-color, #737373);

  /* h4 */
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 150% */
  letter-spacing: 0.2px;
}

.banner-button {
  padding: 15px 40px;
  border-radius: 5px;
  border: 1px solid var(--primary-color, #FF6551);
  cursor: pointer;
  color: var(--primary-color, #FF6551);
  text-align: center;

  /* btn-text */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  /* 157.143% */
  letter-spacing: 0.2px;
}

.container {
  padding-left: 111.609px;
  position: relative;
}

.card-container {
  padding-left: 101.39px;

}

.cards {
  display: flex;
  gap: 30px;
  padding-bottom: 40.09px;
}

.card {
  display: flex;
  width: 253px;
  padding: 35px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  min-height: 225px;
  background: var(--light-background-color, #FFF);
  z-index: 999;
  /* accentued-drop-shadow */
  box-shadow: 0px 13px 19px 0px rgba(0, 0, 0, 0.07);
}

.box1,
.box2,
.box3 {
  display: flex;
  padding: 22px 19px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 35px;
  height: 35px;
  border-radius: 10px;
}

.box1 {
  background: var(--primary-color, #FF6551);
}

.box2 {
  background: var(--secondary-color-1, #2435A1);
}

.box3 {
  background: var(--secondary-color-2, #FFC652);
}



.card-header {
  color: var(--text-color, #252B42);

  /* h3 */
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: 0.1px;
}

.dash {
  width: 50px;
  height: 2px;
  background: var(--danger-color, #E74040);
}

.card-text {
  color: var(--second-text-color, #737373);

  /* paragraph */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.2px;
}

.logo-bottom {
  position: fixed;
  bottom: 23.09px;
  right: 29px;
  z-index: 9999999;
}





/* Add this CSS to your existing styles */
/* Hamburger Menu Animation */
.mobile-menu-trigger {
  display: none;
  cursor: pointer;
}

.mobile-menu-trigger span {
  display: block;
  width: 30px;
  height: 2px;
  background-color: #232F84;
  margin: 6px 0;
  transition: 0.4s;
}

.mobile-menu-trigger.open span:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.mobile-menu-trigger.open span:nth-child(2) {
  opacity: 0;
}

.mobile-menu-trigger.open span:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

/* Responsive Navigation */
@media (max-width: 768px) {
  .nav-menu {
    display: none;
    position: absolute !important;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    background-color: #fff;
justify-content: center;
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }

  .nav-items.open .nav-menu {
    display: flex;
  }

  .nav-links,
  .links1,
  .links2 {
    flex-direction: column;
    gap: 15px;
  }

  .mobile-menu-trigger {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .link2 {
    color: #232F84
  }

  .mobile-menu-trigger.open span {
    background-color: #FFF;
    /* Change color to match your background */
  }

  .name {
    padding-right: 0;
  }

  .close-button {
    padding-bottom: 30px;
    height: 50px;
    width: 50px;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #000000;
    font-size: 40px;
    position: absolute;
    right: 0;
    top: 0;
  }


  .banner {
    display: block;
  }

  .bannertexts,
  .allbannertexts,
  .container,
  .card-container {
    padding-left: 0;
  }

  .cards {
    flex-direction: column;
    justify-content: center;
  }

  .headerlarge,
  .access {
    width: 90%;
    margin: auto
  }

  .banner {
    text-align: center;
  }

  .bannerimage {
    height: 500px;
  }

  .banner-button {
    margin-bottom: 20px;
  }

  .img {
    right: 0;
    top: -50px
  }

  .card {
    align-items: center;
  }

  .card-text {
    text-align: center;
  }

  .container {
    width: 90%;
    margin: auto;
  }
}